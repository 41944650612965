import { useEffect, useState } from 'react';

import LazyImage from './img/image';

interface Props extends React.InputHTMLAttributes<HTMLImageElement>{
  readonly alt?: string;
  readonly placeholderSrc?: string;
  readonly src: string;
}
const ProgressiveImg: React.FC<Props> = ({ className, placeholderSrc, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <LazyImage
      alt={props.alt || 'NOT FOUND'}
      className={className}
      lazy={true}
      src={imgSrc}
    />
  );
};
export default ProgressiveImg;
